// Development Environment 
export const API_SERVER_URL = "http://18.141.212.18:8080"; // "https://www.test-api.opportunitybankhk.org";
export const API_IMAGE_URL = "http://18.141.212.18:8080"; // "https://www.test-api.opportunitybankhk.org";
export const DEFAULT_JWT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPQl9BUFBfVEtOIiwiZW52IjoiVEVTVCIsImFwcCI6Ik9CX1RFU1RfRU5WX01PQklMRV9BUFAiLCJpYXQiOjE2MDY4Mzk1MzYsImV4cCI6MTkyMjE5OTUzNn0.PTdh5ixctt8jWZR7r0a4gB_vkUqgFjRzWp_m5Dr8FTw";


// Production Specific
// export const API_SERVER_URL = "http://54.254.12.58:8080";
// export const API_SERVER_URL = "https://api.opportunitybankhk.org/";
// export const API_IMAGE_URL = "https://api.opportunitybankhk.org/";
// export const API_SERVER_URL_DEV = "http://localhost:8080";
// export const DEFAULT_JWT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPQl9BUFBfVEtOIiwiZW52IjoiUFJPRCIsImFwcCI6Ik9CX1BST0RfRU5WX01PQklMRV9BUFAiLCJpYXQiOjE2MTQ5Mjc5OTIsImV4cCI6MTkzMDI4Nzk5Mn0.v_ZdZq22QFGFbfC76XsTtorR0X3N8riK5110D0gDQPE";
