/* eslint-disable react/jsx-pascal-case */
import logo from "./logo.svg";
import "./App.css";
import SingUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import "./assets/Media-queries.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import OTP_Verification from "./Pages/verify_Email/OTP_Verification";
import Auth_Layout from "./Layout/Auth_Layout";
import Home from "./Pages/home_page/Home";
// import Navbar from './components/Navbar/Navbar';
import Main_Layout from "./Layout/Main_Layout";
import Jobs from "./Pages/Jobs/Jobs";
import EventList from "./Pages/eventModule/EventList";
import JobApply from "./Pages/jobApplied/JobApply";
import JobDetail from "./Pages/jobDetail/JobDetail";
import Service from "./Pages/service/Service";
import Product from "./Pages/product/Product";
import ContactUS from "./Pages/contactUs/ContactUs";
import AboutUS from "./Pages/aboutUs/AboutUs";
import JobList from "./Pages/jobList/JobList";
import PasswordResetPage from "./Pages/password_reset_screens/PasswordResetPage";
import PwdResetOtpScreen from "./Pages/password_reset_screens/PwdResetOtpScreen";
import Profile from "./Pages/profile/Profile";
import JobTrack from "./Pages/jobTrack/JobTrack";
import PrivacyPolicy from "./Pages/privacy_policy/PrivacyPolicy";
import "./assets/common-styles.css";
import ContactInfo from "./Pages/CreateCVForm/contactInfoCv/ContactInfo";
import CreateCVForm from "./Pages/CreateCVForm/CreateCVForm";
import CareerObjective from "./Pages/CreateCVForm/careerObj/CareerObjective";
import Skills from "./Pages/CreateCVForm/skills/Skills";
import Footer from "./components/footer/Footer";
import TermsConditions from "./Pages/TermsConditions";
import CustomizedSteppers from "./components/stepper/ObStepper";
import TestOTP from "../src/Pages/TestOTP";
import ObContactInfo from "./Pages/CreateCVForm/contactInfoCv/ObContactInfo";
import TestStepper from "./components/stepper/TestStepper";
import Test from "../src/Pages/TestOTP";
import OBImpact from "./Pages/OBImpact";
import { useSelector } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import MapView from "../src/Pages/TestOTP";
import RedeemRewards from "./Pages/redeem_rewards/RedeemRewards";
import ProfileView from "./Pages/profile/profileRe.js/ProfileView";
import ProfileContainer from "./Pages/profile/profileRe.js/ProfileContainer";
import "./assets/shimmer.scss";
import Sample from "./Sample";
import Navbar from "../src/components/Navbar/Navbar";
import ComingSoon from "./Pages/ComingSoon";
import TestPage from "./Pages/TestPage";
import WebMobView from "./Pages/mobappview/WebMobView";
import DisplayPopup from "./components/award-popup/DisplayPopup";
import TrainingList from "./Pages/trainingList/TrainingList";
import TrainingDetail from "./Pages/trainingList/TrainingDetail";
import EventDetails from "./Pages/eventModule/EventDetails";
import { useState } from "react";
import MyModalComponent from "./components/contact-modal/contact-modal";
import SocialModal from "./components/contact-modal/SocialModal";
import UsefulModal from "./components/contact-modal/UsefulModal";
import ClaimModal from "./components/claimmodal/ClaimModal";
// import Map from './Pages/Map';

function App({ history }) {
  const isLoggedIn = useSelector((state) => state.profileData.status);
  const currentlink = useSelector((state) => state.currentLink);

  const [showModal, setShowModal] = useState(false);
  const [showSocialModal, setShowSocialModal] = useState(false);
  const [showUsefulModal, setShowUsefulModal] = useState(false);

  const [claiModalIsOpen, setClaimModalOpen] = useState(false);

  console.log("States", isLoggedIn);
  return (
    <div
      className="App"
      style={{ background: "#f9f9fb", position: "relative" }}
    >
      <DisplayPopup />
      {<MyModalComponent show={showModal} setShowModal={setShowModal} />}{" "}
      {<SocialModal show={showSocialModal} setShowModal={setShowSocialModal} />}{" "}
      {<UsefulModal show={showUsefulModal} setShowModal={setShowUsefulModal} />}
      {/* {<ClaimModal show={claiModalIsOpen} setShowModal={setClaimModalOpen} />} */}
      
      <Router>
      {<ClaimModal show={claiModalIsOpen} setShowModal={setClaimModalOpen} />}
        {currentlink === "/signIn" || currentlink === "/signUp" ? (
          ""
        ) : (
          <Navbar />
        )}

        <Switch>
          <PrivateRoute
            path="/jobDetail/:jobId"
            component={() => <Main_Layout component={<JobDetail />} />}
          />
          <PrivateRoute
            path="/trainingDetail/:trainingId"
            component={() => <Main_Layout component={<TrainingDetail />} />}
          />
          <PrivateRoute
            path="/eventDetails/:trainingEventId"
            component={() => <Main_Layout component={<EventDetails />} />}
          />
          <Route path="/OTP" exact component={OTP_Verification} />
          <Route path="/createCV" exact component={CustomizedSteppers} />
          <Route path="/address" exact component={ContactInfo} />
          <Route path="/testMap" exact component={MapView} />
          {/* <Route path='/test' exact component={Map} /> */}
          {/* <Route path='/createCV' exact component={() => <Main_Layout component={<CreateCVForm />} />} /> */}
          <Route path="/contactInfo" exact component={ContactInfo} />
          <Route path="/ObContactInfo" exact component={ObContactInfo} />
          <Route path="/careerObjective" exact component={CareerObjective} />
          <Route path="/skills" exact component={Skills} />
          {!isLoggedIn && (
            <Route
              path="/signin"
              exact
              component={() => <Auth_Layout component={<SignIn />} />}
            />
          )}
          {!isLoggedIn && (
            <Route
              path="/signup"
              component={() => <Auth_Layout component={<SingUp />} />}
            />
          )}
          <Route
            path="/home"
            component={() => (
              <Main_Layout
                component={
                  <Home
                    setShowModal={setShowModal}
                    setShowSocialModal={setShowSocialModal}
                    setShowUsefulModal={setShowUsefulModal}
                    setClaimModalOpen={setClaimModalOpen}
                  />
                }
              />
            )}
          />
          <Route
            path="/jobApply"
            component={() => <Main_Layout component={<JobApply />} />}
          />
          <Route
            path="/service"
            component={() => <Main_Layout component={<Service />} />}
          />
          <Route
            path="/product"
            component={() => <Main_Layout component={<Product />} />}
          />
          <Route
            path="/contactUs"
            component={() => <Main_Layout component={<ContactUS />} />}
          />
          <Route
            path="/aboutUs"
            component={() => <Main_Layout component={<AboutUS />} />}
          />
          <Route
            path="/joblist"
            component={() => <Main_Layout component={<JobList />} />}
          />
          <Route
            path="/traininglist"
            component={() => <Main_Layout component={<TrainingList />} />}
          />
          <Route
            path="/jobs"
            component={() => <Main_Layout component={<Jobs />} />}
          />

          <Route
            path="/jobApply"
            component={() => <Main_Layout component={<JobApply />} />}
          />
          <Route
            path="/trackJob"
            component={() => <Main_Layout component={<JobTrack />} />}
          />
          <Route
            path="/eventList"
            component={() => <Main_Layout component={<EventList />} />}
          />
          <Route
            path="/passwordResetPage"
            component={() => <Auth_Layout component={<PasswordResetPage />} />}
          />
          <Route
            path="/passwordResetOtp"
            component={() => <Auth_Layout component={<PwdResetOtpScreen />} />}
          />
          {/* <Route path='/profile' component={() => <Main_Layout component={<Profile />} />} /> */}
          <Route
            path="/profile"
            component={() => <Main_Layout component={<ProfileContainer />} />}
          />
          <Route
            path="/privacy_policy"
            component={() => <Main_Layout component={<PrivacyPolicy />} />}
          />
          <Route
            path="/terms_conditions"
            component={() => <Main_Layout component={<TermsConditions />} />}
          />
          <Route
            path="/ob_impact"
            component={() => <Main_Layout component={<OBImpact />} />}
          />
          <Redirect from="/count" to="/home" />
          <Route
            path="/count"
            component={() => <Main_Layout component={<RedeemRewards />} />}
          />
          <Route
            path="/count"
            component={() => <Main_Layout component={<RedeemRewards />} />}
          />
          <Route
            path="/sample"
            component={() => <Main_Layout component={<Sample />} />}
          />
          <Route path="/comingsoon" component={ComingSoon} />
          <Route
            path="/webandmob"
            component={() => <Main_Layout component={<WebMobView />} />}
          />
          <Route path="/testPage" component={TestPage} />
          <Redirect to="/home" />
        </Switch>
      </Router>
      {/* <SingUp /> */}
    </div>
  );
}

export default App;
